<template>
  <TTView>
    <VRow>
      <VCol>
        <PositionSearchForm
          :entity="searchData"
          :main-positions="mainPositions"
          :loading="loading"
          @update:name="searchData.name = $event"
          @update:personId="searchData.personId = $event"
          @update:teamId="searchData.teamId = $event"
          @update:main="searchData.main = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <PositionSearchTable
          v-if="staffPositions.length > 0"
          :staff-positions="staffPositions"
          :loading="loading"
        >
          <template #topActions>
            <VDialog
              v-model="confirmDialog"
              max-width="500px"
              @click:outside="resetStaffPositionEdit"
            >
              <VCard>
                <VCardTitle class="headline">
                  Удалить штатную должность
                </VCardTitle>

                <VCardText>
                  Штатная должность <span class="red--text">{{ staffPosition.name }}</span> будет удалена! <br>
                  Действительно хотите удалить штатную должность?
                </VCardText>

                <VCardActions>
                  <VSpacer />

                  <VBtn
                    text
                    @click="resetStaffPositionEdit"
                  >
                    Нет
                  </VBtn>

                  <VBtn
                    color="red darken-1"
                    text
                    @click="handleDelete"
                  >
                    Да
                  </VBtn>
                </VCardActions>
              </VCard>
            </VDialog>
          </template>

          <template #rowActions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="blue"
                  class="mx-1"
                  :to="{
                    name : Names.R_ORGSTRUCTURE_POSITION_VIEW,
                    params : {
                      positionId : item.id
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-eye
                  </VIcon>
                </VBtn>
              </template>

              <span>Просмотр</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="orange"
                  class="mx-1"
                  :to="{
                    name : Names.R_ORGSTRUCTURE_POSITION_EDIT,
                    params : {
                      positionId : item.id
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="deleteStaffPosition(item)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление</span>
            </VTooltip>
          </template>
        </PositionSearchTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import PositionSearchForm from '../../components/org/PositionSearchForm.vue';
import PositionSearchTable from '../../components/org/PositionSearchTable.vue';

export default {
  name: 'OrgPositionSearch',

  components: {
    PositionSearchForm, PositionSearchTable,
  },
  inject: ['Names'],

  data() {
    return {
      loading: false,
      searchData: {
        name: '',
        personId: '',
        teamId: '',
        main: 'MAIN_TRUE',
      },
      mainPositions: [
        { name: 'MAIN_ALL' },
        { name: 'MAIN_INVALID' },
        { name: 'MAIN_TRUE' },
        { name: 'MAIN_FALSE' },
      ],
      staffPositions: [],
      staffPosition: {},
      confirmDialog: false,
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        const { staffPositions = [] } = await this.$di.api.Orgstructure.positionSearch(this.searchData);

        this.staffPositions = staffPositions;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.$router.go(-1);
    },
    async handleDelete() {
      try {
        this.loading = true;

        await this.$di.api.Orgstructure.positionDelete({ id: this.staffPosition.id });

        this.$di.notify.snackSuccess('Позиция удалена');
        this.resetStaffPositionEdit();
        this.handleSubmit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    resetStaffPositionEdit() {
      this.confirmDialog = false;
      this.staffPosition = {};
    },

    deleteStaffPosition(item) {
      this.confirmDialog = true;
      this.staffPosition = { ...item };
    },
  },
};
</script>
