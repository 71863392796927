var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('PositionSearchForm',{attrs:{"entity":_vm.searchData,"main-positions":_vm.mainPositions,"loading":_vm.loading},on:{"update:name":function($event){_vm.searchData.name = $event},"update:personId":function($event){_vm.searchData.personId = $event},"update:teamId":function($event){_vm.searchData.teamId = $event},"update:main":function($event){_vm.searchData.main = $event},"cancel":_vm.handleCancel,"submit":_vm.handleSubmit}})],1)],1),_c('VRow',[_c('VCol',[(_vm.staffPositions.length > 0)?_c('PositionSearchTable',{attrs:{"staff-positions":_vm.staffPositions,"loading":_vm.loading},scopedSlots:_vm._u([{key:"topActions",fn:function(){return [_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.resetStaffPositionEdit},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить штатную должность ")]),_c('VCardText',[_vm._v(" Штатная должность "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.staffPosition.name))]),_vm._v(" будет удалена! "),_c('br'),_vm._v(" Действительно хотите удалить штатную должность? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.resetStaffPositionEdit}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1)]},proxy:true},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"blue","to":{
                  name : _vm.Names.R_ORGSTRUCTURE_POSITION_VIEW,
                  params : {
                    positionId : item.id
                  }
                }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"orange","to":{
                  name : _vm.Names.R_ORGSTRUCTURE_POSITION_EDIT,
                  params : {
                    positionId : item.id
                  }
                }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteStaffPosition(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])])]}}],null,false,987384164)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }