<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      Поиск штатной должности
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Название штатной должности"
                name="name"
                persistent-hint
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.personId"
                label="UUID пользователя"
                name="personId"
                persistent-hint
                @input="$emit('update:personId', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.teamId"
                label="ID команды из оргструктуры"
                name="teamId"
                persistent-hint
                @input="$emit('update:teamId', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.main"
                :items="mainPositions"
                :filter="customFilter"
                item-text="name"
                item-value="name"
                label="Главная штатная должность"
                name="main"
                persistent-hint
                @input="$emit('update:main', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Найти
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'PositionSearchForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    mainPositions: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1;
    },
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
