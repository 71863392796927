<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="staffPositions"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Штатные должности</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'PositionSearchTable',

  props: {
    staffPositions: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Название штатной должности',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Пользователь',
          value: 'personId',
          sortable: false,
        },
        {
          text: 'Команда',
          value: 'teamId',
          sortable: false,
        },
        {
          text: 'Главная штатная должность',
          value: 'main',
          sortable: false,
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
